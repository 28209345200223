import { Link, Picture } from '@troon/ui';
import { Show } from 'solid-js';
import type { State } from '../../../../../graphql';

type Props = {
	state: State;
	cardGroupId: string;
};

export function LocationCard(props: Props) {
	return (
		<div class="relative flex size-full flex-col items-start justify-end overflow-hidden rounded-xl">
			<Show when={props.state.hero?.url} fallback={<span class="absolute inset-0 -z-10 bg-neutral-700" />}>
				{(src) => (
					<Picture
						src={src()}
						sizes={[
							[192, 128],
							[128, 128],
						]}
						// eslint-disable-next-line tailwindcss/no-arbitrary-value
						class="size-full bg-neutral-700 object-cover brightness-[85%]"
					/>
				)}
			</Show>
			<Link
				href={`/card/${props.cardGroupId}/courses/${props.state.id}/`}
				class="absolute inset-0 flex flex-col items-start justify-end p-4 text-white"
			>
				<span class="font-semibold">{props.state.name}</span>
				<span class="text-sm">
					{props.state.troonCardFacilityCount} course{props.state.troonCardFacilityCount !== 1 ? 's' : ''}
				</span>
			</Link>
		</div>
	);
}
